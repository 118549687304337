import revive_payload_client_Frd8gASYWa from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_DgG5Jh65GV from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_T5aD6w6QZh from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aQoh9E5uaZ from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/web/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_9TwXAg33VH from "/vercel/path0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import chunk_reload_client_kCUITUWXUN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/vercel/path0/web/plugins/sentry.client.ts";
import trpc_0KeCZOBKLF from "/vercel/path0/web/plugins/trpc.ts";
import vercel_analytics_client_JwEi2bEO5D from "/vercel/path0/web/plugins/vercel-analytics.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_DgG5Jh65GV,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  preview_client_9TwXAg33VH,
  chunk_reload_client_kCUITUWXUN,
  sentry_client_shVUlIjFLk,
  trpc_0KeCZOBKLF,
  vercel_analytics_client_JwEi2bEO5D
]