import { default as _91404_93c3T19wyUfJMeta } from "/vercel/path0/web/pages/[404].vue?macro=true";
import { default as appBfpS75wJgXMeta } from "/vercel/path0/web/pages/auth/app.vue?macro=true";
import { default as indexjK1GrN0A8gMeta } from "/vercel/path0/web/pages/auth/index.vue?macro=true";
import { default as validate_45emailOEhXMJNHngMeta } from "/vercel/path0/web/pages/auth/validate-email.vue?macro=true";
import { default as betaagX69SKUHaMeta } from "/vercel/path0/web/pages/beta.vue?macro=true";
import { default as cookie_45policyIwB7caD2pPMeta } from "/vercel/path0/web/pages/cookie-policy.vue?macro=true";
import { default as donateaSsX3JOEdYMeta } from "/vercel/path0/web/pages/donate.vue?macro=true";
import { default as exitshOaBeRqUEjMeta } from "/vercel/path0/web/pages/exits.vue?macro=true";
import { default as faqjOMaeDgGbsMeta } from "/vercel/path0/web/pages/faq.vue?macro=true";
import { default as privilege_45escalationxRtCxhW0IhMeta } from "/vercel/path0/web/pages/help/privilege-escalation.vue?macro=true";
import { default as indexjqPwd0e7eHMeta } from "/vercel/path0/web/pages/index.vue?macro=true";
import { default as investor_45accesssghqsZHQS3Meta } from "/vercel/path0/web/pages/investor-access.vue?macro=true";
import { default as investor_45auth4ia4breGRAMeta } from "/vercel/path0/web/pages/investor-auth.vue?macro=true";
import { default as pricingLnBwc1AEexMeta } from "/vercel/path0/web/pages/pricing.vue?macro=true";
import { default as privacy_45policytDn225js2MMeta } from "/vercel/path0/web/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45useebSYFED0vIMeta } from "/vercel/path0/web/pages/terms-of-use.vue?macro=true";
import { default as indexv8wpIM06KTMeta } from "/vercel/path0/web/pages/user/index.vue?macro=true";
import { default as subscription_45portalEsOiOnXQtdMeta } from "/vercel/path0/web/pages/user/subscription-portal.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubUHgG5aOkzG } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91404_93c3T19wyUfJMeta?.name ?? "404",
    path: _91404_93c3T19wyUfJMeta?.path ?? "/:404()",
    meta: _91404_93c3T19wyUfJMeta || {},
    alias: _91404_93c3T19wyUfJMeta?.alias || [],
    redirect: _91404_93c3T19wyUfJMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/[404].vue").then(m => m.default || m)
  },
  {
    name: appBfpS75wJgXMeta?.name ?? "auth-app",
    path: appBfpS75wJgXMeta?.path ?? "/auth/app",
    meta: appBfpS75wJgXMeta || {},
    alias: appBfpS75wJgXMeta?.alias || [],
    redirect: appBfpS75wJgXMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/auth/app.vue").then(m => m.default || m)
  },
  {
    name: indexjK1GrN0A8gMeta?.name ?? "auth",
    path: indexjK1GrN0A8gMeta?.path ?? "/auth",
    meta: indexjK1GrN0A8gMeta || {},
    alias: indexjK1GrN0A8gMeta?.alias || [],
    redirect: indexjK1GrN0A8gMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: validate_45emailOEhXMJNHngMeta?.name ?? "auth-validate-email",
    path: validate_45emailOEhXMJNHngMeta?.path ?? "/auth/validate-email",
    meta: validate_45emailOEhXMJNHngMeta || {},
    alias: validate_45emailOEhXMJNHngMeta?.alias || [],
    redirect: validate_45emailOEhXMJNHngMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/auth/validate-email.vue").then(m => m.default || m)
  },
  {
    name: betaagX69SKUHaMeta?.name ?? "beta",
    path: betaagX69SKUHaMeta?.path ?? "/beta",
    meta: betaagX69SKUHaMeta || {},
    alias: betaagX69SKUHaMeta?.alias || [],
    redirect: betaagX69SKUHaMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/beta.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyIwB7caD2pPMeta?.name ?? "cookie-policy",
    path: cookie_45policyIwB7caD2pPMeta?.path ?? "/cookie-policy",
    meta: cookie_45policyIwB7caD2pPMeta || {},
    alias: cookie_45policyIwB7caD2pPMeta?.alias || [],
    redirect: cookie_45policyIwB7caD2pPMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: donateaSsX3JOEdYMeta?.name ?? "donate",
    path: donateaSsX3JOEdYMeta?.path ?? "/donate",
    meta: donateaSsX3JOEdYMeta || {},
    alias: donateaSsX3JOEdYMeta?.alias || [],
    redirect: donateaSsX3JOEdYMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/donate.vue").then(m => m.default || m)
  },
  {
    name: exitshOaBeRqUEjMeta?.name ?? "exits",
    path: exitshOaBeRqUEjMeta?.path ?? "/exits",
    meta: exitshOaBeRqUEjMeta || {},
    alias: exitshOaBeRqUEjMeta?.alias || [],
    redirect: exitshOaBeRqUEjMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/exits.vue").then(m => m.default || m)
  },
  {
    name: faqjOMaeDgGbsMeta?.name ?? "faq",
    path: faqjOMaeDgGbsMeta?.path ?? "/faq",
    meta: faqjOMaeDgGbsMeta || {},
    alias: faqjOMaeDgGbsMeta?.alias || [],
    redirect: faqjOMaeDgGbsMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: privilege_45escalationxRtCxhW0IhMeta?.name ?? "help-privilege-escalation",
    path: privilege_45escalationxRtCxhW0IhMeta?.path ?? "/help/privilege-escalation",
    meta: privilege_45escalationxRtCxhW0IhMeta || {},
    alias: privilege_45escalationxRtCxhW0IhMeta?.alias || [],
    redirect: privilege_45escalationxRtCxhW0IhMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/help/privilege-escalation.vue").then(m => m.default || m)
  },
  {
    name: indexjqPwd0e7eHMeta?.name ?? "index",
    path: indexjqPwd0e7eHMeta?.path ?? "/",
    meta: indexjqPwd0e7eHMeta || {},
    alias: indexjqPwd0e7eHMeta?.alias || [],
    redirect: indexjqPwd0e7eHMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: investor_45accesssghqsZHQS3Meta?.name ?? "investor-access",
    path: investor_45accesssghqsZHQS3Meta?.path ?? "/investor-access",
    meta: investor_45accesssghqsZHQS3Meta || {},
    alias: investor_45accesssghqsZHQS3Meta?.alias || [],
    redirect: investor_45accesssghqsZHQS3Meta?.redirect,
    component: () => import("/vercel/path0/web/pages/investor-access.vue").then(m => m.default || m)
  },
  {
    name: investor_45auth4ia4breGRAMeta?.name ?? "investor-auth",
    path: investor_45auth4ia4breGRAMeta?.path ?? "/investor-auth",
    meta: investor_45auth4ia4breGRAMeta || {},
    alias: investor_45auth4ia4breGRAMeta?.alias || [],
    redirect: investor_45auth4ia4breGRAMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/investor-auth.vue").then(m => m.default || m)
  },
  {
    name: pricingLnBwc1AEexMeta?.name ?? "pricing",
    path: pricingLnBwc1AEexMeta?.path ?? "/pricing",
    meta: pricingLnBwc1AEexMeta || {},
    alias: pricingLnBwc1AEexMeta?.alias || [],
    redirect: pricingLnBwc1AEexMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policytDn225js2MMeta?.name ?? "privacy-policy",
    path: privacy_45policytDn225js2MMeta?.path ?? "/privacy-policy",
    meta: privacy_45policytDn225js2MMeta || {},
    alias: privacy_45policytDn225js2MMeta?.alias || [],
    redirect: privacy_45policytDn225js2MMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useebSYFED0vIMeta?.name ?? "terms-of-use",
    path: terms_45of_45useebSYFED0vIMeta?.path ?? "/terms-of-use",
    meta: terms_45of_45useebSYFED0vIMeta || {},
    alias: terms_45of_45useebSYFED0vIMeta?.alias || [],
    redirect: terms_45of_45useebSYFED0vIMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: indexv8wpIM06KTMeta?.name ?? "user",
    path: indexv8wpIM06KTMeta?.path ?? "/user",
    meta: indexv8wpIM06KTMeta || {},
    alias: indexv8wpIM06KTMeta?.alias || [],
    redirect: indexv8wpIM06KTMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: subscription_45portalEsOiOnXQtdMeta?.name ?? "user-subscription-portal",
    path: subscription_45portalEsOiOnXQtdMeta?.path ?? "/user/subscription-portal",
    meta: subscription_45portalEsOiOnXQtdMeta || {},
    alias: subscription_45portalEsOiOnXQtdMeta?.alias || [],
    redirect: subscription_45portalEsOiOnXQtdMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/user/subscription-portal.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/jobs",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/careers",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/humans.txt",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/security",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/support",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/lobby",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/matrix",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/x",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/linkedin",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/read-cv",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/status",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  }
]